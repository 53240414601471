// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/general.ts"
);
import.meta.hot.lastModified = "1716509689209.9592";
}
// REMIX HMR END

import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone.js'
import utc from 'dayjs/plugin/utc.js'
import { dequal } from 'dequal/lite'
import { useCallback, useEffect, useRef } from 'react'
import { Key } from 'ts-key-enum'

import { isPOJO } from '@/guards'
import {
  type ObjectWithPrefixedKeys,
  type POJO,
  type SubtractNullish,
} from '@/types'

import { caps } from '~/utils'

dayjs.extend(utc)
dayjs.extend(timezone)

/**
 * Truncates the given `word` to be `characters` long. If the word was
 * truncated, appends the unicode ellipsis character to the end.
 * @param word - the word to truncate.
 * @param characters - the number of characters to include.
 * @returns the truncated word (not including the ellipsis as part of the
 * required character count).
 */
export function truncate(word: string, characters: number) {
  return word.length > characters ? `${word.substring(0, characters)}…` : word
}

export function useRunOnce(effect: React.EffectCallback) {
  const ranOnceRef = useRef(false)
  const useEffectReturnRef = useRef<ReturnType<React.EffectCallback>>()
  useEffect(() => {
    if (!ranOnceRef.current) {
      useEffectReturnRef.current = effect()
      ranOnceRef.current = true
    }
    return useEffectReturnRef.current
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

/**
 * @param val Value on which to check referential equality between renders
 * @param onChangeCallback Callback to run when the value changes.
 * @param deepEqualityCheck Whether to use deep equality check (slower) or shallow equality check
 * @returns Whether the value changed since the last render
 */
export function useValueHasChanged<T>(
  val: T,
  onChangeCallback?: (newValue: T, oldValue: T) => void,
  deepEqualityCheck = false,
) {
  const prevVal = useRef(val)
  const cbRef = useRef(onChangeCallback)
  const deepEqualRef = useRef(deepEqualityCheck)
  useEffect(() => {
    cbRef.current = onChangeCallback
    deepEqualRef.current = deepEqualityCheck
  })
  if (
    (deepEqualRef.current && dequal(prevVal.current, val)) ||
    prevVal.current !== val
  ) {
    cbRef.current?.(val, prevVal.current)
    prevVal.current = val
    return true
  }
  return false
}

export function useInterval(callback: () => void, interval: number) {
  const savedCallback = useRef<() => void>()
  // updated saved callback on every render
  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    const tick = () => savedCallback.current?.()
    if (interval !== null) {
      const id = setInterval(tick, interval)
      return () => clearInterval(id)
    }
  }, [interval])
}

export function hasHorizontalOverflow(element?: HTMLElement | null) {
  if (!element) return false
  return element.offsetWidth < element.scrollWidth
}

export function hasVerticalOverflow(element?: HTMLElement | null) {
  if (!element) return false
  return element.offsetHeight < element.scrollHeight
}

function handleKeyboardClick<T extends HTMLElement>(
  e: React.KeyboardEvent<T>,
  callback: (e: React.KeyboardEvent<T>) => void,
) {
  if (e.key === Key.Enter || e.key === ' ') {
    callback(e)
  }
}

// TODO: move this to utils/a11y.ts
export function useHandleKeyboardClick<T extends HTMLElement>(
  callback: (e: React.KeyboardEvent<T>) => void,
) {
  return useCallback(
    (e: React.KeyboardEvent<T>) => handleKeyboardClick(e, callback),
    [callback],
  )
}

/**
 *
 * @param time ISO string
 * @returns localized time string, e.g. "Jun 13, 10:28 AM"
 */
export function formatLocalTime(time?: string) {
  if (!time) return ''
  const formattedLocalTime = dayjs(time).format('MMM D, h:mm A')
  return formattedLocalTime
}
export function objectWithPrefixedKeys<Prefix extends string, T extends POJO>(
  prefix: Prefix,
  obj: T,
): ObjectWithPrefixedKeys<Prefix, T> {
  if (!isPOJO(obj)) return {} as ObjectWithPrefixedKeys<Prefix, T>
  return Object.entries(obj).reduce<ObjectWithPrefixedKeys<Prefix, T>>(
    (acc, [key, value]) => {
      acc[`${prefix}${caps(key)}` as keyof ObjectWithPrefixedKeys<Prefix, T>] =
        value as ObjectWithPrefixedKeys<Prefix, T>[keyof ObjectWithPrefixedKeys<
          Prefix,
          T
        >]
      return acc
    },
    {} as ObjectWithPrefixedKeys<Prefix, T>,
  )
}

// Contenteditable can leave behind named html code characters. We want to replace those.
// @see {@link https://www.taniarascia.com/content-editable-elements-in-javascript-react/#issue-2-spaces-and-special-characters}
export function sanitizeHTMLNamedCharacters(string: string) {
  return string
    .replace(/&nbsp;/g, '')
    .replace(/&amp;/g, '&')
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<')
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'")
}

export function removeNullishValues<Obj extends POJO>(
  obj: Obj,
): SubtractNullish<Obj> {
  return Object.entries(obj).reduce<Obj>((acc, [key, value]) => {
    // @ts-expect-error TODO: fix later
    if (value != null) acc[key] = value
    return acc
  }, {} as Obj)
}
